<template>
  <div class="mm-main-page">
    <div class="mm-marketplace-wrapper">
      <Header
        :hide-search="!isCatalogAvailable"
        @click-go-to-basket="Basket.goToBasketPage()"
      />
      <div class="mm-main-page__content">
        <slot />
      </div>
      <Footer />
      <GTM />
    </div>
    <ModalsContainer />
    <CompareProductsDrawer />
  </div>
</template>

<script lang="ts" setup>
import GTM from 'shared/components/GTM.vue';
import Header from 'components/layouts/default/Header.vue';
import Footer from 'components/layouts/default/Footer.vue';
import { Categories } from 'services/categories.service';
import { useAuthModal } from 'composables/useAuthHelper';
import { IModalManager } from 'shared/models/modalManager.model';
import ModalManager from 'shared/services/modalManager.service';
import BasesService from 'services/basesManager.service';
import { useServerSsoAuth } from 'composables/useAuthHelper';
import { useMainTitle } from 'composables/useMainTitle';
import { Basket } from 'services/basket-services/basket/basket.service';
import { useCheckInternalUser } from 'shared/composables/useCheckInternalUser';
import setAuthCookieHeaders from '../composables/setAuthCookieHeaders';
import { useCompareProducts } from '../composables/useCompareProducts';
import { useRefreshBasketData } from 'composables/useRefreshBasketData';
import CompareProductsDrawer from 'components/compare-products/CompareProductsDrawer.vue';
import { useCatalog } from 'composables/useCatalog';

setAuthCookieHeaders();
await useDomainAuth();
useAuthModal();

provide(Categories.getServiceName(), new Categories());

const basesService = inject<BasesService>(BasesService.getServiceName());
const modalManager = process.client ? inject<IModalManager>(ModalManager.serviceName) : undefined;

await useServerSsoAuth();
await useSetServerUserInfo();
const { isCatalogAvailable } = useCatalog();
useMainTitle();
useSetClientUserInfo();
useCheckInternalUser();
useRefreshBasketData();

useCompareProducts();

onBeforeUnmount(() => Categories.killInstance());

onBeforeMount(async () => {
  await nextTick();
  basesService?.initBases(modalManager);
  await basesService?.getPopulation();
});
</script>

<style lang="scss" scoped>
.mm-main-page {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  :deep(.mm-header) + div {
    flex: 0 0 640px;
  }

  &::after {
    content: '';
    width: 100%;
    height: 10px;
    flex: 0 0 1px;
  }

  &.overflow {
    overflow: hidden;
  }

  :deep(.mm-header) {
    position: relative;
    z-index: 30;
  }

  &__content {
    min-height: 700px;
  }
}

@media screen and (max-width: 1279px) {
  .mm-marketplace-wrapper {
    padding-bottom: 56px;

    :deep(.mm-header) {
      margin-top: 30px;
    }
  }
}
</style>
